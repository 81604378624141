@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-in {
  animation: slideIn 0.4s ease-out forwards;
}

.slide-out {
  animation: slideOut 0.4s ease-out forwards;
}

@layer components {
  .form-card {
    @apply flex flex-col items-center bg-white p-10 rounded-xl shadow-lg max-w-xl w-full mx-2;
  }

  .form-button {
    @apply bg-primary text-white font-bold py-4 px-8 rounded-full hover:bg-opacity-90 transition duration-300 text-lg w-full max-w-sm mt-6;
  }

  .form-input {
    @apply w-full p-4 border border-gray-300 rounded-lg mb-6 focus:ring-2 focus:ring-primary focus:ring-opacity-50 focus:border-primary focus:outline-none transition duration-200 text-lg;
  }

  .form-select {
    @apply w-full p-4 border border-gray-300 rounded-lg mb-6 focus:ring-2 focus:ring-primary focus:ring-opacity-50 focus:border-primary focus:outline-none transition duration-200 bg-white text-lg;
  }
}

/* src/index.css or your global stylesheet */
.card-large {
  @apply p-12 max-w-3xl md:w-full mx-4 md:mx-auto;
}

.button-large {
  @apply text-xl py-4 px-8 min-w-[200px];
}

.title-primary {
  @apply text-primary text-3xl;
}
